type GoogleAnalyticsEventAction = 'startFreeTrial' | 'freeTrial' | 'upsellingLearnMore';

const getTrackingAttributes = (action: GoogleAnalyticsEventAction, trigger: string) => ({
    'data-track-ga-event-trigger': trigger,
    'data-track-ga-event-category': 'marketplace',
    'data-track-ga-event-action': `${action}_${trigger}`,
});

export const getStartFreeTrialTrackingAttributes = (trigger: 'click' | 'visibility') =>
    getTrackingAttributes('startFreeTrial', trigger);

export const getLearnMoreTrackingAttributes = (trigger: 'click' | 'visibility') =>
    getTrackingAttributes('upsellingLearnMore', trigger);
