import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { config } from './config';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { ErrorBoundary } from './features/app/ErrorBoundary';
import App from './features/app/App';

const renderApplication = () => {
    createRoot(document.getElementById('root') as HTMLElement).render(
        <ErrorBoundary>
            <Provider store={store}>
                <App />
            </Provider>
        </ErrorBoundary>
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
