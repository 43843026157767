import { isThirdPartyTelematicsDeviceType } from './common/deviceTypes';

export const checkoutBaseUrl = process.env.REACT_APP_CHECKOUT_ROUTE;

export function getCheckoutUrl(deviceType: string | null | undefined, resourceIds: Array<string>): string {
    const commaSeparatedResources =
        resourceIds.length > 0 && resourceIds.length <= 200 ? resourceIds.join(',') : undefined;
    const resourceIdParam = commaSeparatedResources !== undefined ? `&resourceIds=${commaSeparatedResources}` : '';

    if (isThirdPartyTelematicsDeviceType(deviceType)) {
        return `${checkoutBaseUrl}/?productId=rio-sku00000047&level=34&popup=true${resourceIdParam}`;
    }
    return `${checkoutBaseUrl}/?productId=rio-sku00000047&level=10&popup=true${resourceIdParam}`;
}
