import { getPopupWindowProps } from '../common/popupService';
import { FormattedMessage } from 'react-intl';
import { getWindowScreen } from '../common/screenWrapper';
import { getStartFreeTrialTrackingAttributes } from '../common/googleTagManagerAttributes';

export const MARKETPLACE_START_FREE_TRIAL = 'https://marketplace.rio.cloud/startFreeTrial';

export const FreeTrialButton = () => {
    const targetPath = `${MARKETPLACE_START_FREE_TRIAL}`;
    const windowId = 'free-trial';
    const { windowName, sizeString } = getPopupWindowProps(getWindowScreen(), windowId, 'freeTrialButton');

    return (
        <div className={'buyButtonLink'} {...getStartFreeTrialTrackingAttributes('visibility')}>
            <a
                className={'btn btn-secondary text-size-18'}
                onClick={() => window.open(targetPath, windowName, sizeString)}
                {...getStartFreeTrialTrackingAttributes('click')}
            >
                <FormattedMessage id={'intl-msg:upselling.freeTrial.button'} />
            </a>
        </div>
    );
};
