import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getDisplayMessages } from '../../configuration/lang/langSlice';

const messagePrefix = 'intl-msg:upselling.features.';

export function UpsellingProductFeatures(props: { productId: string }) {
    const productId = props.productId.toLowerCase();
    const displayMessages = useSelector(getDisplayMessages);

    if (displayMessages?.[`${messagePrefix}${productId}.1`]) {
        return (
            <ul
                className='feature-list text-center margin-x-10pct-ls margin-y-10 text-size-12 text-size-14-sm'
                id='features'
            >
                {['1', '2', '3']
                    .map((number) => `${messagePrefix}${productId}.${number}`)
                    .filter((messageId) => displayMessages?.[messageId] !== undefined)
                    .map((messageId, index) => (
                        <li key={index} className='rounded-circle padding-x-20 bg-highlight-decent'>
                            <FormattedMessage id={messageId} />
                        </li>
                    ))}
            </ul>
        );
    }
    return null;
}
