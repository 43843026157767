import {mapSkuToName} from "./common/mapProductSkuToProductName";
import {useAppSelector} from "../../configuration/setup/hooks";
import {getLocale} from "../../configuration/lang/langSlice";

const BUY_BUTTON_LINK = 'https://buybutton.marketplace.rio.cloud/?productId=';

export const BuyButton = ({productId, level, resourceId}: {
    productId: string,
    level?: string,
    resourceId?: string,
}) => {
    const preferredLanguage = useAppSelector(getLocale);
    return <iframe
        className='buyButton'
        src={`${BUY_BUTTON_LINK}${productId}&level=${level}${
            resourceId ? `&resourceIds=${resourceId}` : ''
        }&internalCampaign=upselling-${encodeURI(mapSkuToName(productId))}${
            preferredLanguage ? `&locale=${preferredLanguage}` : ''
        }`}
    />;
}
