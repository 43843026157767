import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }> {
    static propTypes = {
        children: PropTypes.node
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtra('componentStack', errorInfo.componentStack);
            Sentry.captureException(error);
        });
    }

    render() {
        return this.props.children;
    }
}
