/* eslint-disable no-undef */
import * as Sentry from '@sentry/react';
import { config } from '../../config';

declare const SERVICE_VERSION: string;
declare const SERVICE_ENVIRONMENT: string;

if (process.env.NODE_ENV === 'production') {
    // version and environment are defined in the webpack.define plugin
    const release = SERVICE_VERSION;
    const environment = SERVICE_ENVIRONMENT;

    // should have been called before using it here
    // ideally before even rendering your react app
    Sentry.init({
        dsn: config.sentryToken,
        ignoreErrors: [
            'Network Error',
            'Blocked a frame with origin "https://upselling.marketplace.rio.cloud" from accessing a cross-origin frame',
            'Need to sign in',
            /Loading chunk \w+ failed/,
        ],
        environment,
        release,
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportErrorToSentry = (...args: [any, any?]) => {
    Sentry.captureException(...args);
};
