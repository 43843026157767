import * as t from 'io-ts';
import { FreeTrialResponse, Product } from '../../freeTrial/redux/types';

const ProductDecoder: t.Type<Product> = t.type({
    // eslint-disable-next-line camelcase
    product_id: t.string,
    level: t.string,
});

const FreeTrialResponseDecoder: t.Type<FreeTrialResponse> = t.intersection([
    t.type({
        status: t.union([t.literal('eligible'), t.literal('ineligible'), t.literal('active')]),
    }),
    t.partial({
        // eslint-disable-next-line camelcase
        ends_at: t.string,
        products: t.array(ProductDecoder),
    }),
]);

export { FreeTrialResponseDecoder };
