import { FormattedMessage, useIntl } from 'react-intl';
import { openContactFormInPopup } from '../common/popupService';
import { getDateFromIsoString } from '../common/utils/dateUtil';
import { useGetFreeTrialStatusQuery } from '../api/marketplaceApi';

export function FreeTrialInformationBox() {
    const freeTrialQuery = useGetFreeTrialStatusQuery();
    const freeTrialEndDateIsoString = freeTrialQuery.data?.endsAt;
    const freeTrialEndDate = getDateFromIsoString(freeTrialEndDateIsoString);
    const intl = useIntl();

    if (!freeTrialEndDate) {
        return null;
    }

    const endDate = intl.formatDate(freeTrialEndDate, { timeZone: 'UTC' });

    return (
        <div className='bg-primary margin-top-20 rounded'>
            <div className='display-flex-ls flex-1-1-ls padding-top-2pct position-relative overflow-hidden'>
                <div className='container-fluid display-flex-ls flex-1-1-ls fluid-default'>
                    <div className='row display-flex-ls flex-wrap-ls flex-1-1-ls justify-content-center-ls'>
                        <div className='display-flex justify-content-center'>
                            <div>
                                <div className='well padding-x-50-sm padding-top-25-sm padding-bottom-0 bg-primary display-flex-sm align-items-end-sm max-width-1000 gap-25 position-relative'>
                                    <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-10 blur-50'></div>
                                    <div className='text-color-white line-height-125rel position-relative z-index-1'>
                                        <div className='text-medium text-size-h3 margin-bottom-20'>
                                            <FormattedMessage
                                                id={'marketplace.freeTrialTab.informationBox.title'}
                                                values={{ date: endDate }}
                                            />
                                        </div>
                                        <div className='text-light'>
                                            <p className='margin-y-10'>
                                                <FormattedMessage
                                                    id={'marketplace.freeTrialTab.informationBox.text1'}
                                                    values={{
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </p>
                                            <p className='margin-y-10'>
                                                <FormattedMessage
                                                    id={'marketplace.freeTrialTab.informationBox.text2'}
                                                />
                                            </p>
                                            <p className='margin-y-10'>
                                                <FormattedMessage
                                                    id={'marketplace.freeTrialTab.informationBox.text3'}
                                                    values={{
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        bold: (chunks: Array<string>) => <strong>{chunks}</strong>,
                                                    }}
                                                />
                                            </p>
                                            <hr className='border-color-highlight-light'></hr>
                                            <p className='margin-y-10'>
                                                <FormattedMessage
                                                    id={'marketplace.freeTrialTab.informationBox.text4'}
                                                    values={{
                                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                        // @ts-ignore
                                                        link: (chunks: Array<string>) => (
                                                            <a
                                                                className='text-color-white text-decoration-underline'
                                                                onClick={() =>
                                                                    openContactFormInPopup('freeTrialInfoContactPopup')
                                                                }
                                                            >
                                                                {chunks}
                                                            </a>
                                                        ),
                                                    }}
                                                />
                                            </p>
                                            <div className=''>
                                                <small>
                                                    <FormattedMessage
                                                        id={'marketplace.freeTrialTab.informationBox.note'}
                                                    />
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
