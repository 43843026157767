import { FormattedMessage } from 'react-intl';

const UnauthorizedError = () => (
    <div className='display-flex justify-content-center max-width-100pct'>
        <div className='margin-0 width-100pct max-width-600 panel-default panel padding-15'>
            <div className='display-flex flex-column align-items-center text-center padding-25'>
                <div className='text-size-h2 margin-bottom-20'>
                    <span className='rioglyph text-color-light rioglyph-truck-breakdown text-size-300pct'></span>
                </div>
                <div className='text-size-h3 text-size-h2-sm line-height-125rel text-color-dark text-medium width-500 max-width-100pct'>
                    <FormattedMessage id='intl-msg:upselling.unauthorized' />
                </div>
            </div>
        </div>
    </div>
);

export default UnauthorizedError;
