export interface Bookable {
    activation?: ActivationStatus;
    status: boolean;
}

export enum ActivationStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    PENDING_CANCELLATION = 'pending-cancellation',
    PENDING_ACTIVATION = 'pending-activation',
}
