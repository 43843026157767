import { decodeJson } from '../api';
import { ApiBookableResourcesCodec } from './bookable.types';
import { ActivationStatus, Bookable } from '../../bookable/redux/types';

export const mapApiBookables = (apiBookables: unknown, resourceId: string | undefined): Bookable => {
    if (resourceId) {
        const resourceInfo = decodeJson(ApiBookableResourcesCodec)(apiBookables).resources.find(
            (resource) => resource.resource_id === resourceId
        );
        if (resourceInfo) {
            let activation = ActivationStatus.INACTIVE;
            if (resourceInfo.activation_state === 'active') {
                activation = ActivationStatus.ACTIVE;
            } else if (resourceInfo.activation_state === 'inactive') {
                activation = ActivationStatus.INACTIVE;
            } else if (resourceInfo.activation_state === 'pending-activation') {
                activation = ActivationStatus.PENDING_ACTIVATION;
            } else if (resourceInfo.activation_state === 'pending-cancellation') {
                activation = ActivationStatus.PENDING_CANCELLATION;
            }

            return {
                status: resourceInfo.bookable,
                activation,
            };
        }
        return {
            status: false,
        };
    }
    return {
        status: decodeJson(ApiBookableResourcesCodec)(apiBookables).resources.some((resource) => resource.bookable),
    };
};
