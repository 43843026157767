import 'iframe-resizer/js/iframeResizer.contentWindow'; // Required for resizing when included as iframe
import { FreeTrialButton } from './FreeTrialButton';
import { useEffect } from 'react';
import manPointingRight from './images/man_pointing_right.webp';
import { FormattedMessage } from 'react-intl';
import { gsap } from 'gsap';
import { useSearchParams } from 'react-router-dom';
import { useGetFreeTrialStatusQuery } from '../api/marketplaceApi';
import { FreeTrialInformationBox } from './FreeTrialInformationBox';
import { isThirdPartyTelematicsDeviceType } from '../common/deviceTypes';

function animateLetters() {
    document.querySelectorAll('.letters').forEach((letter) => {
        if (letter.textContent) {
            letter.innerHTML = letter.textContent.replace(/\S/g, `<span class='letter'>$&</span>`);
        }
    });

    const letterAnimation = gsap.timeline({ repeat: -1, delay: 1, repeatDelay: 2 });
    document.querySelectorAll('.letter').forEach((letter) => {
        letterAnimation.from(
            letter,
            {
                duration: 1,
                opacity: 0,
                ease: 'power4.out',
            },
            '-=0.9'
        );
    });
}

export function HomescreenFreeTrialSection() {
    const freeTrialQuery = useGetFreeTrialStatusQuery();

    const [searchParams] = useSearchParams();
    const deviceType = searchParams.get('deviceType');
    const hideFreeTrial = isThirdPartyTelematicsDeviceType(deviceType);

    useEffect(() => {
        animateLetters();
    });

    if (freeTrialQuery.data && freeTrialQuery.data.status === 'eligible' && !hideFreeTrial) {
        return (
            <div className='bg-primary margin-top-20 rounded'>
                <div className='display-flex-ls flex-1-1-ls padding-top-2pct position-relative overflow-hidden'>
                    <div className='container-fluid display-flex-ls flex-1-1-ls fluid-default'>
                        <div className='row display-flex-ls flex-wrap-ls flex-1-1-ls justify-content-center-ls'>
                            <div className='col-xs-12 margin-bottom-0 display-flex-ls'>
                                <div className='display-flex-ls flex-1-1-ls flex-column-ls'>
                                    <div className='width-100pct text-color-white padding-x-5pct'>
                                        <div className='width-100pct max-width-800 rounded-circle bg-white aspect-ratio-1 position-absolute top-0 left-50pct translate-x-50pct-y-50pct opacity-30 blur-50'></div>
                                        <div className='display-flex gap-50 justify-content-center align-items-end'>
                                            <img
                                                className='img-responsive max-width-150 max-width-300-md position-relative display-none-xs display-none-ls display-block-sm'
                                                src={manPointingRight}
                                                alt='Man pointing right'
                                            />
                                            <div className='position-relative padding-top-2pct'>
                                                <div className='text-size-20 text-bold margin-bottom-5 display-flex gap-20'>
                                                    <div>
                                                        <span className='rioglyph rioglyph-ok-sign'></span>
                                                    </div>
                                                    <div className='letters'>
                                                        <FormattedMessage
                                                            id={'marketplace.freeTrial.homeScreenAnimation1'}
                                                        ></FormattedMessage>
                                                    </div>
                                                </div>
                                                <div className='text-size-20 text-bold margin-bottom-5 display-flex gap-20'>
                                                    <div>
                                                        <span className='rioglyph rioglyph-ok-sign'></span>
                                                    </div>
                                                    <div className='letters'>
                                                        <FormattedMessage
                                                            id={'marketplace.freeTrial.homeScreenAnimation2'}
                                                        ></FormattedMessage>
                                                    </div>
                                                </div>
                                                <div className='text-size-20 text-bold margin-bottom-5 display-flex gap-20'>
                                                    <div>
                                                        <span className='rioglyph rioglyph-ok-sign'></span>
                                                    </div>
                                                    <div className='letters'>
                                                        <FormattedMessage
                                                            id={'marketplace.freeTrial.homeScreenAnimation3'}
                                                        ></FormattedMessage>
                                                    </div>
                                                </div>
                                                <div className='max-width-50vw-xs max-width-250 transform-origin-top-left margin-top-25 padding-bottom: 17px'>
                                                    <div className='buyButton'>
                                                        <FreeTrialButton />
                                                    </div>
                                                </div>
                                                <div className='max-width-700 text-size-12 margin-y-25'>
                                                    <FormattedMessage
                                                        id={'marketplace.freeTrial.homeScreen'}
                                                    ></FormattedMessage>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        {/* force rendering margin */}
                    </div>
                </div>
            </div>
        );
    }
    if (freeTrialQuery.data && freeTrialQuery.data.status === 'active' && !hideFreeTrial) {
        return <FreeTrialInformationBox />;
    }
    return <div style={{ height: 0 }} />;
}
